import { graphql } from 'gatsby';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Container from '../components/container';
import Hero from '../components/hero';
import Layout from '../components/layout';
import Section from '../components/section';
import { H2, P } from '../components/typography';
import { fluidImageType, nodesOf } from '../helper/prop-types';
import { mq } from '../helper/stylehelper';

const ImageWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;

    ${mq.medium`
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        max-width: ${rem(600)};
    `};
`;

const Logo = styled.img`
    display: block;
    margin: 0 0 ${rem(45)} 0;

    ${mq.medium`
        margin: 0 ${rem(20)} 0 0;
    `};

    &:last-child {
        margin: 0;
    }
`;

const WhereToBuyPage = ({ data }) => (
    <Layout
        title="Hier die Original Lechtaler Wurst kaufen"
        description="Die Original Lechtaler finden sie bei ausgewählten Handelspartnern. Hier bekommen Sie einen Überblick!"
        conclusionBoxes={['products', 'aboutus']}
    >
        <Hero image={data.file.childImageSharp.fluid} title="Service" />
        <Section>
            <Container maxWidth="small">
                <H2 textCentered largeFont>
                    Hier gibt‘s Die Original Lechtaler
                </H2>
                <P textCentered intro gap="xxl">
                    Die Original Lechtaler Sortenvielfalt findet ihr bei folgenden Handelspartnern:
                    <br />
                    Edeka Hessenring, Edeka Rhein Ruhr, Edeka Nordbayern, Edeka Südwest, Globus,
                    V-MARKT.
                </P>
            </Container>
            <Container maxWidth="medium">
                <ImageWrapper>
                    {data.logos.edges.map(({ node }) => (
                        <Logo src={node.publicURL} key={node.publicURL} alt="Logo" />
                    ))}
                </ImageWrapper>
            </Container>
        </Section>
    </Layout>
);

WhereToBuyPage.propTypes = {
    data: PropTypes.shape({
        file: fluidImageType,
        logos: nodesOf(
            PropTypes.shape({
                publicURL: PropTypes.string.isRequired,
            })
        ),
    }).isRequired,
};

export default WhereToBuyPage;

export const query = graphql`
    query {
        file(relativePath: { eq: "keyvisual/keyvisual-bread.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
        logos: allFile(
            filter: { relativePath: { glob: "content/retailer/logo-*.png" } }
            sort: { fields: relativePath, order: ASC }
        ) {
            edges {
                node {
                    publicURL
                }
            }
        }
    }
`;
